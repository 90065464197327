<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                dir="rtl"
                v-model="formData.arabic_title"
                :rules="textRules"
                label="arabic title"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="formData.english_title"
                :rules="textRules"
                label="english title"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="formData.iso_code"
                :rules="textRules"
                label="iso code"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="formData.dialing_code"
                :rules="textRules"
                label="dialing code"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="formData.currency_id"
                :items="currencies"
                dense
                chips
                small-chips
                label="Currency"
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["formTitle", "record", "dialog", "collection"],
  data() {
    return {
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      loadingCurrency: false,
      currencies: [],

      formData: {
        id: null,
        arabic_title: null,
        english_title: null,
        iso_code: null,
        dialing_code: null,
        currency_id: null,
      },
      Languages: [],
      textRules: [(v) => !!v || "field is required"],
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },

  methods: {
    async removeimg(id) {
      const { data } = await ApiService.delete(`files/${id}`);
      this.formData["image"] = null;
      this.submit();
      // let msg;
      // msg = "image deleted";
      // alert(msg);
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          if (this.image) {
            // send file
            let formData1 = new FormData();
            formData1.append("data", this.image[0]);
            // await this.postFile(formData1);
            const file = await ApiService.postFile("files", formData1, 0, null);
            // send post req
            this.formData["image"] = file.data.data.id;
          }
          if (this.record) {
            const { data } = await ApiService.update(
              `items/${this.collection}`,
              this.formData.id,
              this.formData
            );
          } else {
            const { data } = await ApiService.post(
              `items/${this.collection}`,
              this.formData
            );
          }
          this.submitted = false;
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
  },

  async mounted() {
    if (this.record) {
      Object.keys(this.formData).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.formData[element] = this.record[element].id;
          } else {
            this.formData[element] = this.record[element];
          }
        }
      });
    }
    const currencies = await ApiService.get("items/currency?fields=id,title");
    this.currencies = currencies.data.data;
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
